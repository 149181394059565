import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { first } from 'rxjs/operators';
import { GeoLocationService } from './services/geo-location.service';
import { NotificationService } from './services/notification.service';
import { FcmService } from './services/fcm.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  connexion = false;

  constructor(
    public platform: Platform,
    public statusBar: StatusBar,
    public splashScreen: SplashScreen,
    private router: Router,
    private userService: UserService,
    private geoLocation: GeoLocationService,
    private notificationService: NotificationService,
    private fcmService: FcmService,
  ) {
    platform.ready().then(() => {
      statusBar.styleLightContent();
      splashScreen.hide();

      if (platform.is('android')) {
        // diagnostic.requestLocationAuthorization().then(result => console.log(result));
      }

      this.checkConnexion();
    });
  }

  checkConnexion() {
    this.userService.user$.pipe(first()).subscribe((user) => {
      if (!user) {
        this.router.navigate(['/login']);
      } else {
        console.log('local user', user);
        this.userService.refreshUserData();
        this.fcmService.initPush();
      }
    });
  }
}
