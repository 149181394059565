import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  authenticationToken: string;
  refreshToken: string;
  user$: BehaviorSubject<User | null> = new BehaviorSubject(null);
  user: User;

  constructor(private http: HttpClient) {
    console.log('load user data');
    this.authenticationToken = localStorage.getItem('authentication_token') || null;
    this.refreshToken = localStorage.getItem('refresh_token') || null;
    let user: string | User = localStorage.getItem('user');

    if (user && user !== 'undefined') {
      user = JSON.parse(user) as User;
      this.user = user;
      this.user$.next(user);
    }
  }

  setAuthenticationToken(authenticationToken) {
    this.authenticationToken = authenticationToken;
    localStorage.setItem('authentication_token', authenticationToken);
  }

  setRefreshToken(refreshToken) {
    this.refreshToken = refreshToken;
    localStorage.setItem('refresh_token', refreshToken);
  }

  login(email: string, password: string) {
    return this.http.post(environment.serverUrl + 'api/authentication_token', {
      email,
      password,
    });
  }

  resetPassword(email: string) {
    const headers = new HttpHeaders({
      'X-Requested-With': 'XMLHttpRequest',
    });

    const formData: any = new FormData();
    formData.append('email', email);

    return this.http.post(`${environment.oauthUrl}forgot-password`, formData, { headers });
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  getRefreshedTokens() {
    return this.http.post(environment.serverUrl + 'api/refresh_token', {
      refresh_token: this.refreshToken,
    });
  }

  getUserData() {
    return this.http.get(environment.serverUrl + 'api/users/me');
  }

  storeUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this.user = user;

    this.user$.next(user);
  }

  refreshUserData() {
    this.getUserData().subscribe(
      (response: any) => {
        this.storeUser(response);
      },
      (error) => {
        console.error(`couldn't update user data`, error);
      },
    );
  }
}
