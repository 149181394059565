import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { UserSettings } from '../models/user.model';
import { LocationAddress, LocationPhone } from '../models/location.model';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { filter, first, flatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { fromArray } from 'rxjs/internal/observable/fromArray';
import { Coords } from '@transistorsoft/capacitor-background-geolocation';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  settings$: BehaviorSubject<UserSettings | null> = new BehaviorSubject(null);
  addressLocations$: BehaviorSubject<LocationAddress[] | null> = new BehaviorSubject([]);
  phoneLocation$: BehaviorSubject<LocationPhone | null> = new BehaviorSubject(null);
  phoneLocationLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private userService: UserService, private http: HttpClient) {
    this.userService.user$.pipe().subscribe((user) => {
      if (user) {
        this.settings$.next(user.userSettings);
        this.getLocations();
      }
    });
  }

  updateNotificationSetting(payload) {
    return this.http.put(environment.serverUrl + 'api/users/me/settings', payload);
  }

  updateAddress(id: number, payload) {
    return this.http.put(`${environment.serverUrl}api/location_addresses/${id}`, payload);
  }

  getLocations(queryLocations = ['location_phones', 'location_addresses']) {
    fromArray(queryLocations)
      .pipe(flatMap((locationType) => this.http.get(`${environment.serverUrl}api/${locationType}`)))
      .subscribe((locationsCollection: { 'hydra:member': (LocationAddress | LocationPhone)[] }) => {
        const locations = locationsCollection['hydra:member'];

        if (locations.length <= 0) {
          console.log(`No ${locationsCollection['@context']} for this account yet`);
          return;
        }

        if (locationsCollection['@context'] === '/api/contexts/LocationPhone') {
          const phoneLocation = locations.filter(
            (location) => location.title === 'Géolocalisation mobile' && location.user === '/api/users/' + this.userService.user.id,
          ) as LocationPhone[] | null;

          if (phoneLocation && phoneLocation.length >= 1) {
            this.phoneLocation$.next(phoneLocation[0]);
            this.phoneLocationLoaded$.next(true);
          }
        } else if (locationsCollection['@context'] === '/api/contexts/LocationAddress') {
          const addressLocations = locations.filter((location) => location.title !== 'Géolocalisation mobile') as LocationAddress[];
          this.addressLocations$.next(addressLocations);
        }
      });
  }

  updatePhoneLocation(location: Partial<Coords>) {
    this.phoneLocationLoaded$
      .pipe(
        filter((loaded) => loaded),
        first(),
        withLatestFrom(this.phoneLocation$),
        switchMap(([locationsLoaded, phoneLocation]) => {
          if (!locationsLoaded) {
            return of(null);
          }

          const newLocation = {
            title: 'Géolocalisation mobile',
            latitude: location.latitude,
            longitude: location.longitude,
            isActive: true,
          };

          if (phoneLocation) {
            return this.http.put(`${environment.serverUrl}api/location_phones/${phoneLocation.id}`, newLocation);
          }

          console.error('No phone number associated');
        }),
      )
      .subscribe(
        (result) => {
          console.log(result);
          this.getLocations(['location_phones']);
        },
        (error) => {
          console.error(error);
        },
      );
  }
}
