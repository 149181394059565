import { Injectable } from '@angular/core';
import { AlertController, isPlatform, Platform } from '@ionic/angular';
import { SettingsService } from './settings.service';
import { filter, first, switchMap, switchMapTo } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, zip } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';

import BackgroundGeolocation, { Location, Subscription, Config, State } from '@transistorsoft/capacitor-background-geolocation';

@Injectable({
  providedIn: 'root',
})
export class GeoLocationService {
  private pluginReady = new BehaviorSubject(false);
  private config = {};

  constructor(
    private platform: Platform,
    private settings: SettingsService,
    private user: UserService,
    private alertController: AlertController,
  ) {
    if (!isPlatform('cordova')) {
      return;
    }

    console.log('GeoLocationService starting');

    this.config = {
      reset: true,
      debug: true,
      logLevel: BackgroundGeolocation.LOG_LEVEL_DEBUG,
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: 500,
      disableElasticity: true,
      fastestLocationUpdateInterval: 60000,
      autoSync: true,
      maxRecordsToPersist: 1,
      stopOnTerminate: false,
      startOnBoot: true,
      httpRootProperty: '.',
      locationTemplate: '{ "latitude":<%= latitude %>, "longitude":<%= longitude %> }',
      method: 'PUT',
      notificationTitle: 'Géolocalisation',
      notificationText: 'Activé',
      schedule: ['2-6 9:00-18:00'],
      backgroundPermissionRationale: {
        title: 'Autoriser {applicationName} à accéder à la localisation de cet appareil en arrière-plan',
        // eslint-disable-next-line max-len
        message: `Pour le bon fonctionnement de l'application, veuillez activer l'autorisation de la localisation {backgroundPermissionOptionLabel}.`,
        positiveAction: 'Changer vers {backgroundPermissionOptionLabel}',
        negativeAction: 'Annuler',
      },
    } as Config;

    combineLatest(this.settings.settings$, this.settings.phoneLocation$)
      .pipe(
        filter(([userSettings, phoneLocation]) => userSettings !== null && phoneLocation !== null),
        first(),
        // Start setup of geoloc plugin once all needed data is present
        switchMap(
          ([userSettings, phoneLocation]): Promise<void> =>
            new Promise((resolve, reject) => {
              console.log('Setup geoloc plugin');

              this.platform.ready().then(() => {
                // 1.  Listen to events.
                BackgroundGeolocation.onLocation((location: Location) => {
                  if (location.sample) {
                    return;
                  }
                  console.log('[location] - ', location);
                });

                /*              BackgroundGeolocation.onMotionChange(event => {
                console.log('[motionchange] - ', event.isMoving, event.location);
              });*/

                BackgroundGeolocation.onHttp((response) => {
                  console.log('[http] - ', response.success, response.status, response.responseText);
                });

                /*              BackgroundGeolocation.onProviderChange(event => {
                console.log('[providerchange] - ', event.enabled, event.status, event.gps);
              });*/

                BackgroundGeolocation.onAuthorization((event) => {
                  if (event.success) {
                    console.log('[authorization] ERROR: ', event.error);
                  } else {
                    console.log('[authorization] SUCCESS: ', event.response);
                    this.user.setAuthenticationToken(event.response.token);
                    this.user.setRefreshToken(event.response.refresh_token);
                  }
                });

                // 2.  Configure the plugin with #ready
                BackgroundGeolocation.ready({
                  ...this.config,
                  url: `${environment.serverUrl}api/location_phones/${phoneLocation.id}`,
                  authorization: {
                    strategy: 'JWT',
                    accessToken: this.user.authenticationToken,
                    refreshToken: this.user.refreshToken,
                    refreshUrl: environment.serverUrl + 'api/refresh_token',
                    refreshPayload: {
                      refresh_token: '{refreshToken}',
                    },
                  },
                }).then((state: State) => {
                  console.log('[ready] BackgroundGeolocation is ready to use');
                  this.pluginReady.next(true);
                  resolve();
                });
              });
            }),
        ),
        // switchMapTo ends the former obersvable stream that sets up the plugin
        // now we just wanna know if the user changes the gps setting and react
        switchMapTo(this.settings.settings$),
      )
      .subscribe((userSettings) => {
        console.log('GPS active', userSettings.hasGps);
        BackgroundGeolocation.getState().then((state) => {
          if (userSettings.hasGps && !state.enabled) {
            this.startWatching();
          } else if (!userSettings.hasGps && state.enabled) {
            this.stopWatching();
          }
        });
      });
  }

  async startWatching() {
    await this.prominentDisclosureForBackgroundLocation();
    console.log('start watching');

    if (!isPlatform('cordova')) {
      return;
    }

    this.pluginReady
      .pipe(
        filter((active) => active),
        first(),
      )
      .subscribe(() => {
        // BackgroundGeolocation.start().then(() => console.log('started'));
        BackgroundGeolocation.startSchedule().then(() => console.log('schedule started'));
        console.log('start GeoLocation');
      });
  }

  stopWatching() {
    console.log('stop watching');

    if (!isPlatform('cordova')) {
      return;
    }

    this.pluginReady
      .pipe(
        filter((active) => active),
        first(),
      )
      .subscribe(() => {
        BackgroundGeolocation.stopSchedule().then(() => console.log('stopped'));
        BackgroundGeolocation.stop().then(() => console.log('schedule stoppped'));
        console.log('stop GeoLocation');
      });
  }

  async prominentDisclosureForBackgroundLocation() {
    if (localStorage.getItem('k3d_prominentdisclosure') === 'true') {
      return;
    } else {
      const alert = await this.alertController.create({
        header: 'Localisation en arrière-plan ',
        message:
          'France Nuisibles collecte vos données de localisation pour permettre à un client de trouver ' +
          'le professionnel le plus proche. Nous ne sauvegardons que votre dernière position pour ' +
          'permettre le calcul de la distance entre ce client et vous.',
        buttons: ['Fermer'],
      });

      await alert.present();
      await alert.onDidDismiss();
      localStorage.setItem('k3d_prominentdisclosure', 'true');
    }
  }
}
