import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { Notification } from '../models/notification.model';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { filter, first, flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications$: BehaviorSubject<Notification[] | null> = new BehaviorSubject(null);

  constructor(private userService: UserService, private http: HttpClient) {}

  getNotifications() {
    return this.http.get(`${environment.serverUrl}api/notifications`).pipe(
      flatMap((notificationsCollection: { 'hydra:member': Notification[] }) => {
        const notifications = notificationsCollection['hydra:member'];

        if (notifications.length <= 0) {
          console.log('No Notifications for this account yet');
          this.notifications$.next([]);
          return of([]);
        }

        notifications.reverse();
        console.log(notifications);
        this.notifications$.next(notifications);
        return of(notifications);
      }),
    );
  }

  getNotification(id: number) {
    return this.http.get(`${environment.serverUrl}api/notifications/${id}`);
  }

  updateNotification(id: number, payload: { status: string; statusSource: string }) {
    return this.http.put(`${environment.serverUrl}api/notifications/${id}`, payload);
  }
}
