import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { UserService } from './user.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('authentication_token')) {
      return next.handle(request);
    }

    // if there is a problem with the refresh_token logout, otherwise broken state of app
    if (request.url.includes('refresh_token')) {
      return next.handle(request).pipe(
        catchError((error) => {
          this.userService.logout();
          return throwError(error);
        }),
      );
    }

    const authReq = this.addToken(request, this.userService.authenticationToken);

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        }

        return throwError(error);
      }),
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      console.log('refresh token');
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.userService.getRefreshedTokens().pipe(
        switchMap((token: any) => {
          this.userService.setAuthenticationToken(token.token);
          this.userService.setRefreshToken(token.refresh_token);

          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => next.handle(this.addToken(request, token))),
      );
    }
  }
}
