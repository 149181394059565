import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpRequestService } from './services/http-request.service';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';

export let errrorHandler: any = {
  provide: ErrorHandler,
  useClass: ErrorHandler,
};

if (environment.production || environment.beta) {
  Sentry.init({
    dsn: environment.sentryDsn,
    release: environment.appVersion,
    environment: environment.beta ? 'staging' : 'prod',
    integrations: (integrations) => integrations.filter((i) => i.name !== 'TryCatch'),
    tracesSampleRate: 1.0,
  });
  errrorHandler = {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler(),
  };

  const PATH_STRIP_RE = /(http|capacitor):\/\/localhost/;
  const normalizeUrl = (url: string, pathStripRe: RegExp): string => url.replace(pathStripRe, '');

  Sentry.addGlobalEventProcessor((data: any) => {
    if (data.culprit) {
      data.culprit = normalizeUrl(data.culprit, PATH_STRIP_RE);
    }
    const stacktrace = data.stacktrace || (data.exception && data.exception.values && data.exception.values[0].stacktrace);
    if (stacktrace) {
      stacktrace.frames.forEach((frame: any) => {
        if (frame.filename !== '[native code]' && frame.filename !== '<anonymous>') {
          frame.filename = normalizeUrl(frame.filename, PATH_STRIP_RE);
        }
      });
    }
    console.log(data);
    return data;
  });
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestService, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    errrorHandler,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
