import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SettingsService } from './settings.service';
import { first, switchMap } from 'rxjs/operators';
import { PushNotification, PushNotificationActionPerformed, PushNotifications, PushNotificationToken } from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  constructor(private settings: SettingsService) {}

  initPush() {
    if (Capacitor.platform !== 'web') {
      this.registerPush();
    }
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      console.log('My token: ' + JSON.stringify(token));
      this.settings.settings$
        .pipe(
          first(),
          switchMap(() =>
            this.settings.updateNotificationSetting({
              pushToken: token.value,
            }),
          ),
        )
        .subscribe((data) => {
          console.log('pushtoken updated');
        });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotification) => {
      console.log('Push received: ' + JSON.stringify(notification));
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: PushNotificationActionPerformed) => {
      const data = notification.notification.data;
      console.log('Action performed: ' + JSON.stringify(notification.notification));
    });
  }
}
